import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Wettersteen Advisory & Consult', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Wettersteen',
  name: 'Advisory & Consult',
  subtitle: 'Strategic councilling for startups, private equity and venture capital',
  cta: 'More',
};

// ABOUT DATA
export const aboutData = {
  img: 'wt-logo.png',
  paragraphOne:
    'We advise tech. startups on organisation, hiring and product development. We invest in smart companies with potential.',
  paragraphTwo:
    'We advise private equity and venture capital firms on prospects through competent due dilligence',
  paragraphThree:
    "We advise private equity and venture capital firm's portfolio growth companies. We specialise in geographical expansion, organisational expansion and growth-phase product development",
  // resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  /*
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  }, */
];

// CONTACT DATA
export const contactData = {
  cta:
    'Our primary references are by word-of-mouth, but we do our best to accomodate any inquiries.',
  btn: 'Inquiries',
  email: 'sune@wettersteen.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    /*
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    }, */
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
